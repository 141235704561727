import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ReactComponent as AccountIcon } from '../../assets/icon/account.svg'
import { useEffect } from "react";

export const HelpPage = () => {
    const { t } = useTranslation();

    const sections: any = t("help.how-to.section", { returnObjects: true });
    const additionals: any = t("help.additionals", { returnObjects: true });
    const qa: any = t("help.qa", { returnObjects: true });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const createMmsMarkup = (message: string) => {
        return {
            __html: message
        };
    }

    return (
        <>
            <Helmet>
                <title>{t("help.how-to.title")}</title>
                <meta name="description" content={t('app.description')} />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/en/help`} hrefLang="en" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/hi/help`} hrefLang="hi" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/id/help`} hrefLang="id" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/th/help`} hrefLang="th" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/vi/help`} hrefLang="vi" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/zh/help`} hrefLang="zh" />
            </Helmet>

            <div className="w-auto max-w-[60rem] pt-10 md:px-10 mx-5 md:m-auto">
                <div className="rounded-xl p-3 md:mx-20 my-10 flex border border-sky-500">
                    <div className="w-auto h-[120px] bg-green-500 rounded-full">
                        <AccountIcon className="h-full" />
                    </div>
                    <div className="flex justify-center text-justify">
                        <h2 className="my-auto mx-6 text-[18px]"> {t('help.open')} </h2>
                    </div>
                </div>
                <h1 className="mb-10 text-3xl text-center font-semibold text-green-700">{t("help.how-to.title")}</h1>
                {
                    sections.map((section: any, i: number) => (
                        <div style={{ marginBottom: '30px' }} key={i} className="ml-5 max-w-[94%]">
                            <h2 className="mb-2 text-lg font-semibold text-green-600">{i + 1}. {section.title}</h2>
                            <ul className="space-y-1 text-gray-900 list-disc list-inside">
                                {
                                    section.step.map((step: string, i: number) => (
                                        <li className="leading-6" key={i} dangerouslySetInnerHTML={createMmsMarkup(step)}
                                        />
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                }

                {
                    additionals.map((additional: any, i: number) => (
                        <div style={{ marginBottom: '40px' }} key={i} className="max-w-[94%] ">
                            <h1 className="mb-10 text-center text-3xl font-semibold text-green-700">{additional.title}</h1>
                            <ul className="ml-5 space-y-1 text-gray-900 list-disc list-inside">
                                {
                                    additional.tips.map((tip: string, i: number) => (
                                        <li className="leading-8" key={i} dangerouslySetInnerHTML={createMmsMarkup(tip)}
                                        />
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                }
                <h1 className="mb-10 text-center text-3xl font-semibold text-green-700">{qa.title}</h1>
                {
                    qa.questions.map((question: any, i: number) => (
                        <div style={{ marginBottom: '30px' }} key={i} className="ml-6 max-w-[94%]">
                            <h2 className="mb-2 text-lg font-semibold text-green-600">{question.question}</h2>
                            <p className="leading-6 text-gray-900" dangerouslySetInnerHTML={createMmsMarkup(question.answer)} />
                        </div>
                    ))
                }
            </div>
        </>
    );
}