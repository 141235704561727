import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const AboutPage = () => {
    const { t } = useTranslation();
    const abouts: any = t("about", { returnObjects: true });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const createMmsMarkup = (message: string) => {
        return {
            __html: message
        };
    }

    return (
        <>
            <Helmet>
                <title>{abouts["about-us"]?.title}</title>
                <meta name="description" content={t('app.description')} />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/en/about`} hrefLang="en" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/hi/about`} hrefLang="hi" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/id/about`} hrefLang="id" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/th/about`} hrefLang="th" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/vi/about`} hrefLang="vi" />
                <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/zh/about`} hrefLang="zh" />
            </Helmet>
            <div className="w-auto max-w-[60rem] pt-20 md:px-10 mx-5 md:m-auto">
                <div className="mb-10">
                    <h3 className="text-2xl font-bold my-5 text-green-700">{abouts["about-us"]?.title}</h3>
                    {
                        abouts["about-us"]?.content?.map((about: any, i: number) => (
                            <div key={i} className="max-w-[94%]">
                                <p className="text-base leading-8 my-5" dangerouslySetInnerHTML={createMmsMarkup(about)} />
                            </div>
                        ))
                    }
                </div>
                <div className="mb-10">
                    <h3 className="text-2xl font-bold my-5 text-green-700">{abouts["why-choose-us"]?.title}</h3>
                    {
                        abouts["why-choose-us"]?.content?.map((about: any, i: number) => (
                            <ul key={i} className="max-w-[94%] list-disc list-inside">
                                <li className="text-base leading-8" dangerouslySetInnerHTML={createMmsMarkup(about)} />
                            </ul>
                        ))
                    }
                </div>
                <div className="mb-10">
                    <h3 className="text-2xl font-bold my-5 text-green-700">{abouts["how-it-works"]?.title}</h3>
                    <div className="max-w-[94%]">
                        <p className="text-base leading-8 my-5" dangerouslySetInnerHTML={createMmsMarkup(abouts["how-it-works"]?.content)} />
                    </div>
                </div>
                <div className="mb-10">
                    <h3 className="text-2xl font-bold my-5 text-green-700">{abouts["contact-us"]?.title}</h3>
                    <div className="max-w-[94%]">
                        <p className="text-base leading-8 my-5" dangerouslySetInnerHTML={createMmsMarkup(abouts["contact-us"]?.content)} />
                    </div>
                </div>
            </div>
        </>
    );
}