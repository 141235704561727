import { ReactNode } from "react";
import bannerBg from '../../assets/banner-bg.webp';

export const Banner: React.FC<{ children: ReactNode }> = ({ children }) => {

    return (
        <div style={{ backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2) ), url(" + bannerBg + ")", backgroundSize: "cover" }}
            className='content-center text-white w-full
m-auto min-h-[14rem] md:min-h-[16rem] relative shadow-lg shadow-gray-300' >
            {children}
        </div>
    );
}