import { useEffect, useState } from "react";
import Card from "../../componants/card/Cards";
import { LoadingDots } from "../../componants/loading/Loadings";
import { NumberBoxesItem } from "../../models/Message";
import { getSmss } from "../../service/ApiService";
import { useGlobalContext } from "../../GlobalContext";
import { useTranslation } from "react-i18next";

export const NumbersView: React.FC<{ langNav: string, type: string, setError: (mess: string) => void }> = ({ langNav, type, setError }) => {
    const [loading, setLoading] = useState(true);

    const [smsDetails, setSmsDetails] = useState<NumberBoxesItem[]>([]);
    const { globalVariable, setGlobalVariable } = useGlobalContext()
    const { t } = useTranslation()

    useEffect(() => {
        getSmss(type).then((data) => {
            setSmsDetails(data);
            setGlobalVariable({
                ...globalVariable,
                smsDetails: data
            })
        }).catch((err: Error) => {
            setError(err.message);
        }).finally(() => setLoading(false));
    }, []);

    return (<>
        {
            loading ? <div className='w-full flex content-center justify-center'> <LoadingDots /> </div> :
                smsDetails?.sort((o1, o2) => (o1.countryCode >= o2.countryCode ? 1 : -1))
                    .map((sms: NumberBoxesItem, i) => (
                        <div key={i} className=" p-2 sm:p-4 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 mb-0 sm:mb-4">
                            <Card
                                number={sms.number}
                                numberCode={sms.numberCode}
                                countryCode={sms.countryCode}
                                countryName={t(`countyName.${sms.countryCode}`)}
                                langNav={langNav}
                            />
                        </div>
                    ))
        }
    </>)
}