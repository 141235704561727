// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

import translationEN from "../locales/en/translation.json";
import translationHI from "../locales/hi/translation.json";
import translationID from "../locales/id/translation.json";
import translationTH from "../locales/th/translation.json";
import translationVI from "../locales/vi/translation.json";
import translationZH from "../locales/zh/translation.json";

const supportedLanguages = ["en", "hi", "id", "th", "vi", "zh"];

// i18n initialization
i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      hi: {
        translation: translationHI,
      },
      id: {
        translation: translationID,
      },
      th: {
        translation: translationTH,
      },
      vi: {
        translation: translationVI,
      },
      zh: {
        translation: translationZH,
      },
    },
    fallbackLng: "en",
    supportedLngs: supportedLanguages,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
