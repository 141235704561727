import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../GlobalContext";

export const LanguageSwitcher: React.FC = () => {
    const { globalVariable, setGlobalVariable } = useGlobalContext()
    const { i18n } = useTranslation();
    const { lng } = useParams<{ lng: string }>();
    const navigate = useNavigate();

    const supportedLanguages = i18n?.options?.supportedLngs as string[];

    useEffect(() => {
        if (supportedLanguages.includes(lng!)) {
            if (i18n.language !== lng) {
                i18n.changeLanguage(lng)
                setGlobalVariable({
                    ...globalVariable,
                    lang: lng
                })
            }
        } else {
            navigate('/')
        }
    }, [lng, i18n]);

    return (
        <>
            <Outlet />
        </>
    );
};