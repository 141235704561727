import React, { createContext, useContext, useState, ReactNode } from 'react';
import { NumberBoxesItem } from './models/Message';

interface GlobalContextType {
    globalVariable: GlobalVariable;
    setGlobalVariable: React.Dispatch<React.SetStateAction<GlobalVariable>>;
}

interface GlobalVariable {
    number?: string;
    countryCode?: string;
    lang?:string;
    smsDetails?: NumberBoxesItem[];
}

// Create the context with a default value
const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

// Create a provider component
export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [globalVariable, setGlobalVariable] = useState<GlobalVariable>({});

    return (
        <GlobalContext.Provider value={{ globalVariable, setGlobalVariable }}>
            {children}
        </GlobalContext.Provider>
    );
};

// Custom hook to use the GlobalContext
export const useGlobalContext = (): GlobalContextType => {
    const context = useContext(GlobalContext);
    if (context === undefined) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }
    return context;
};