import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getNumberInfo, getSmsDetails } from '../../service/ApiService';
import Message from '../../componants/message/Message';
import { SmsDetails } from '../../models/Message';
import { LoadingDots } from '../../componants/loading/Loadings';
import { getFlag } from '../../service/ResourceService';
import { ErrorPage } from '../error/ErrorPage';
import { Tooltip } from '../../componants/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Banner } from '../../componants/banner/Banner';
import refreshicon from '../../assets/icon/refresh_icon.png';
import infoIcon from '../../assets/icon/icons8-info-64.png';
import ReactGA from "react-ga4";

const SmsDetailPage = () => {
  const { t } = useTranslation()

  const { smsNumberId } = useParams();
  const [smsDetail, setSmsDetail] = useState<SmsDetails | null>(null);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [copyMsg, setCopyMsg] = useState(t("sms-detail-page.click-to-copy"));
  const [countryCode, setCountryCode] = useState(undefined);

  const number = smsNumberId?.includes('-') ? smsNumberId!.split("-")[1] : smsNumberId!;


  const hints: any =
    t("sms-detail-page.hints", { returnObjects: true });


  useEffect(() => {
    ReactGA.send({ hitType: `+${number}`, page: `/sms/${number}`, title: "Sms detail" });
  }, [])

  useEffect(() => {
    if (!smsNumberId) {
      return;
    }
    fetchSmssDetail();
  }, [smsNumberId]);

  useEffect(() => {
    if (!smsNumberId) {
      return;
    }
    getNumberInfo(number)
      .then((data: any) => {
        setCountryCode(data.countryCode);
      })
      .catch((err: Error) => {
        console.log("Error", err);
        setError(err.message);
      });
  }, [smsNumberId, number]);

  const fetchSmssDetail = async () => {
    getSmsDetails(number)
      .then((data) => {
        setSmsDetail(data);
      })
      .catch((err: Error) => {
        console.log("Error", err);
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  const clickToCopy = () => {
    navigator.clipboard.writeText(number)
      .then(() => setCopyMsg(t("sms-detail-page.copied")));
  }

  return (
    <>
      <Helmet>
        <title>{`+${number} | ${t('sms-detail-page.receive-sms', { country: t(`countyName.${countryCode}`) })}`}</title>
        <meta name="description" content={t('app.description')} />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/en/sms/${smsNumberId}`} hrefLang="en" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/hi/sms/${smsNumberId}`} hrefLang="hi" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/id/sms/${smsNumberId}`} hrefLang="id" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/th/sms/${smsNumberId}`} hrefLang="th" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/vi/sms/${smsNumberId}`} hrefLang="vi" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/zh/sms/${smsNumberId}`} hrefLang="zh" />
      </Helmet>
      {
        error ? <ErrorPage message={t(error)} /> :
          <>
            <div className='flex flex-col content-center text-gray-800 m-auto relative shadow-lg shadow-gray-300' >
              <Banner>
                <div className='my-10'>
                  {
                    <div className="w-[9rem] md:w-[10rem] min-h-[6rem] relative mx-auto" >
                      <img className="w-full bot-0" src={getFlag(countryCode!)} alt={`${t('app.flag-img-alt')}: ${countryCode!}`} />
                    </div>
                  }
                  <div style={{ fontFamily: "Roboto,sans-serifn" }} className='text-center flex justify-center relative mx-auto mt-2'>
                    <Tooltip message={copyMsg}>
                      <h1 onClick={clickToCopy} className='text-white text-[1.7rem] md:text-[2.2rem] hover:cursor-pointer hover:underline font-normal'>{`+${number}`}</h1>
                    </Tooltip>
                  </div>
                  <div className='text-center mt-5'>
                    <h1 className='text-white font-sans text-[1rem] md:text-[1.6rem]'>{t('sms-detail-page.receive-sms', { country: t(`countyName.${countryCode}`) })} </h1>
                  </div>
                </div>
              </Banner>
              <div className='bg-white p-6'>
                <div style={{ fontFamily: 'Open Sans", sans-serif' }} className='text-left max-w-[36rem] text-[0.8rem] md:text-[1.1rem] relative mx-auto mt-1'>
                  <ul className='list-disc'>
                    {
                      hints.map((hint: any, key: number) => (
                        <li key={key} className='mt-3'>
                          {hint}
                        </li>
                      ))
                    }
                  </ul>
                </div>
                <div className='flex justify-center mt-6'>
                  <button type="button" onClick={async () => {
                    setLoading(true);
                    fetchSmssDetail();
                  }} disabled={loading}
                    className="disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:cursor-wait
                      focus:outline-none text-white bg-green-700 
                      hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg 
                      text-lg pl-10 px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 
                      dark:focus:ring-green-800 h-14 w-[16rem] relative"><img src={refreshicon} className='absolute left-9 top-[1rem] h-2/5' alt="refresh icon" /> {t("sms-detail-page.update-messages")} </button>
                </div>
              </div>
            </div>
            <div className='mt-4 bg-white flex flex-wrap md:w-[90%] lg:w-[70%] min-h-[70vh] h-auto m-auto relative bg-gray-100 pt-8 '>
              {
                loading ? <div className='w-full flex justify-center'> <LoadingDots /> </div> :

                  <div className='relative mx-auto bg-white p-0 md:p-4 min-h-[100%]'>
                    {/* <p>Most recent text messages:</p> */}
                    {
                      smsDetail?.messages?.map((msg, key) => <Message key={key} message={msg.message} time={msg?.time} timeUnit={msg?.timeUnit} sender={msg.sender} />)
                    }
                    {
                      smsDetail?.messages?.length === 0 &&
                      <div className='m-auto'>
                        <img className='w-10 m-auto my-2' src={infoIcon} alt='info icon' />
                        <div className='text-center mx-6 text-gray-600'>
                          {t('sms-detail-page.no-sms-info')}
                        </div>
                      </div>
                    }
                  </div>
              }
            </div>
          </>
      }
    </>
  );
};

export default SmsDetailPage;