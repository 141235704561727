import { useEffect, useState } from 'react';
import { ErrorPage } from '../error/ErrorPage';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactGA from "react-ga4";
import { Banner } from '../../componants/banner/Banner';
import { NumbersView } from './NumbersView';
import { CountriesView } from './CountriesView';

const enum HomeType {
  POPULAR_NUMBER = "popularNumber",
  All_COUNTRIES = "allCountries",
  COUNTRY_NUMBER = "countryNumber",
}

const HomePage = () => {
  const { t } = useTranslation();

  const { lng, countryCode } = useParams<{ lng: string, countryCode: string }>();
  const { pathname } = useLocation();
  const [error, setError] = useState<string | undefined>();
  const [homeType, setHomeType] = useState<HomeType>(HomeType.POPULAR_NUMBER);

  const langNav = lng ? `/${lng}` : '';

  const activeButtonStyle = "border-2 border-green-500 text-green-500 hover:cursor-default";
  const inactiveButtonStyle = "border border-gray-400 text-gray-400 hover:cursor-pointer hover:text-green-500 hover:border-green-500";

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home page" });
  }, [])

  useEffect(() => {
    if (!pathname.includes('country')) {
      window.scrollTo(0, 0);
      setHomeType(HomeType.POPULAR_NUMBER);
    } else if (countryCode) {
      window.scrollTo(0, 0);
      setHomeType(HomeType.COUNTRY_NUMBER);
    } else {
      setHomeType(HomeType.All_COUNTRIES);
    }
  }, [pathname])


  return (
    <>
      {
        homeType === HomeType.POPULAR_NUMBER &&
        <Helmet>
          <title>{t('app.title')}</title>
          <meta name="description" content={t('app.description')} />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/en/`} hrefLang="en" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/hi/`} hrefLang="hi" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/id/`} hrefLang="id" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/th/`} hrefLang="th" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/vi/`} hrefLang="vi" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/zh/`} hrefLang="zh" />
        </Helmet>
      }
      {
        homeType === HomeType.All_COUNTRIES &&
        <Helmet>
          <title>{t('app.title')}</title>
          <meta name="description" content={t('app.description')} />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/en/country/`} hrefLang="en" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/hi/country/`} hrefLang="hi" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/id/country/`} hrefLang="id" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/th/country/`} hrefLang="th" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/vi/country/`} hrefLang="vi" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/zh/country/`} hrefLang="zh" />
        </Helmet>
      }
      {
        homeType === HomeType.COUNTRY_NUMBER &&
        <Helmet>
          <title>{t('app.title')}</title>
          <meta name="description" content={t('app.description')} />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/en/country/${countryCode}/`} hrefLang="en" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/hi/country/${countryCode}/`} hrefLang="hi" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/id/country/${countryCode}/`} hrefLang="id" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/th/country/${countryCode}/`} hrefLang="th" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/vi/country/${countryCode}/`} hrefLang="vi" />
          <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/zh/country/${countryCode}/`} hrefLang="zh" />
        </Helmet>
      }
      {
        error ? <ErrorPage message={t(error)} /> :
          <>
            <Banner>
              <div className='my-5'>
                <div className='text-2xl md:text-6xl my-5 md:my-8 relative text-center '>
                  <h1>{t("home-page.title")}</h1>
                </div>
                <div className='text-xl md:text-3xl my-5 md:my-8 alegreya-sc-regular relative text-center'>
                  <h2>{t("home-page.second-title")}</h2>
                </div>
                <div className='text-md md:text-lg my-5 md:my-8 text-center relative'>
                  <p> {t("home-page.description-1")}
                    <br /> {t("home-page.description-2")}</p>
                </div>
              </div>
            </Banner>
            <div className='flex flex-wrap justify-center md:w-3/4 w-full m-auto mt-6'>
              <div className='flex w-[10rem] md:w-[16rem] mx-1 my-2 md:mx-4'>
                <Link to={`${langNav}/`} className={`h-10 content-center text-center text-sm md:text-lg bg-white w-full rounded-full 
                  ${homeType === HomeType.POPULAR_NUMBER ? activeButtonStyle : inactiveButtonStyle}`}>
                  <p className='font-semibold' >{t('home-page.popular-numbers')}</p>
                </Link>
              </div>
              <div className='flex w-[10rem] md:w-[16rem] mx-1 my-2 md:mx-4'>
                <Link to={`${langNav}/country`} className={`h-10 content-center text-center text-sm md:text-lg bg-white w-full rounded-full 
                  ${homeType === HomeType.All_COUNTRIES ? activeButtonStyle : inactiveButtonStyle}`}>
                  <p className='font-semibold' >{t('home-page.all-countries')}</p>
                </Link>
              </div>
              {homeType === HomeType.COUNTRY_NUMBER &&
                <div className='flex w-[10rem] md:w-[16rem] mx-1 my-2 md:mx-4'>
                  <Link to={`${langNav}/country`} className={`h-10 content-center text-center text-sm md:text-lg bg-white w-full rounded-full 
                  ${activeButtonStyle}`}>
                    <p className='font-semibold' >{t(`countyName.${countryCode}`)}</p>
                  </Link>
                </div>
              }
            </div>
            {
              homeType === HomeType.POPULAR_NUMBER &&
              <div className='flex flex-wrap md:w-[90%] max-w-[80rem] lg:w-[70%] min-h-[70vh] h-auto m-auto relative pt-8 pb-16'>
                <NumbersView type='p' langNav={langNav} setError={setError} />
              </div>
            }
            {
              homeType === HomeType.All_COUNTRIES &&
              <div className='grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 md:w-[90%] max-w-[80rem] lg:w-[70%] min-h-[70vh] h-auto m-auto relative pt-8 pb-16'>
                <CountriesView langNav={langNav} setError={setError} />
              </div>
            }
            {
              homeType === HomeType.COUNTRY_NUMBER &&
              <div className='flex flex-wrap md:w-[90%] max-w-[80rem] lg:w-[70%] min-h-[70vh] h-auto m-auto relative pt-8 pb-16'>
                <NumbersView type={countryCode!} langNav={langNav} setError={setError} />
              </div>
            }
          </>
      }
    </>
  );
};

export default HomePage;