import { Link } from "react-router-dom";
import { getFlag } from "../../service/ResourceService";

export const CountryCard = (props: {
  countryCode: string;
  countryQuantiny: number;
  countryName: string;
  langNav: string;
}) => {

  return (
    <Link to={`${props.langNav}/country/${props.countryCode}`} className="flex w-[10rem] h-[9rem] bg-teal-50 justify-center rounded-3xl border border-gray-300
        cursor-pointer group relative shadow-md">
      <div className="m-auto relative mt-6 w-[6rem] group-hover:scale-125">
        <div className="absolute rounded-full bg-white text-center border border-gray-300 
          -top-2 -right-2 w-7 h-7">
          <span className="text-sm text-green-700 font-medium">{props.countryQuantiny}</span>
        </div>
        <img className="w-full h-auto min-h-[2.5rem] max-h-[4.5rem] border border-gray-200 rounded-md" src={getFlag(props.countryCode)} alt="" />
      </div>
      <div className="font-semibold w-full bottom-5 absolute text-center whitespace-nowrap text-gray-500">
        <p>{props.countryName}</p>
      </div>
    </Link>
  )
}