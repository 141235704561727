const BASE_URL = process.env.REACT_APP_SERVER_DOMAIN;

export const getSmss = async (t:string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/phone?t=${t}`);

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("error-page.api-error.500");
      } else if (response.status === 500) {
        throw new Error("error-page.api-error.500");
      } else {
        throw new Error("error-page.api-error.500");
      }
    }

    return await response.json();
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getSmsDetails = async (smsId: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/phone/sms/${smsId}`);
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("error-page.api-error.sms-not-found");
      } else if (response.status === 500) {
        throw new Error("sms-detail-page.not-available");
      } else {
        throw new Error(`Unexpected error: ${response.status}`);
      }
    }
    return await response.json();
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getNumberInfo = async (smsId: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/phone/${smsId}`);
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("error-page.api-error.sms-not-found");
      } else if (response.status === 500) {
        throw new Error("error-page.api-error.500");
      } else {
        throw new Error(`Unexpected error: ${response.status}`);
      }
    }
    return await response.json();
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getAllCountries = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/country`);
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("error-page.api-error.sms-not-found");
      } else if (response.status === 500) {
        throw new Error("error-page.api-error.500");
      } else {
        throw new Error(`Unexpected error: ${response.status}`);
      }
    }
    return await response.json();
  } catch (error: any) {
    throw new Error(error.message);
  }
};
