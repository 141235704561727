import './App.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import SmsDetailPage from './pages/sms-detail/SmsDetailPage';
import HomePage from './pages/home/HomePage';
import Header from './pages/Header';
import Footer from './pages/Footer';
import { GlobalProvider } from './GlobalContext';
import { NotFoundPage } from './pages/error/ErrorPage';
import { useEffect } from 'react';
import ReactGA from "react-ga4";
import { LanguageSwitcher } from './configuration/LanguageSwitcher';
import { HelpPage } from './pages/help/HelpPage';
import { AboutPage } from './pages/about/AboutPage';

function App() {

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID!);
  }, [])

  return (
    <>

      <BrowserRouter>
        <GlobalProvider>
          <Routes>
            <Route path="/" element={<Layout />} >
              <Route path="/:lng" element={<LanguageSwitcher />} >
                <Route path="/:lng/" element={<HomePage />} />
                <Route path="/:lng/country" element={<HomePage />} />
                <Route path="/:lng/country/:countryCode" element={<HomePage />} />
                <Route path="/:lng/sms/:smsNumberId" element={<SmsDetailPage />} />
                <Route path="/:lng/about" element={<AboutPage />} />
                <Route path="/:lng/help" element={<HelpPage />} />
                <Route path="/:lng/*" element={<NotFoundPage />} />
              </Route>
              <Route path="/" element={<HomePage />} />
              <Route path="/country" element={<HomePage />} />
              <Route path="/country/:countryCode" element={<HomePage />} />
              <Route path="/sms/:smsNumberId" element={<SmsDetailPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </GlobalProvider>
      </BrowserRouter>
    </>
  );
}

const Layout: React.FC = () => {
  return (
    <>
      <Header />
      <div className='pb-[8rem]'>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};


export default App;
