import { t } from "i18next";
import { useEffect, useState } from "react";
import { LoadingDots } from "../../componants/loading/Loadings";
import { getAllCountries } from "../../service/ApiService";
import { Country } from "../../models/Country";
import { CountryCard } from "../../componants/card/CountryCard";


export const CountriesView: React.FC<{ langNav: string, setError: (mess: string) => void }> = ({ langNav, setError }) => {
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState<Country[]>([]);

    useEffect(() => {
        getAllCountries().then((data) => {
            setCountries(data);
        }).catch((err: Error) => {
            setError(err.message);
        }).finally(() => setLoading(false));
    }, []);

    return (<>
        {
            loading ? <div className='w-full flex content-center justify-center'> <LoadingDots /> </div> :
                countries?.map((country: Country, i) => (
                    <div key={i} className="m-auto">
                        <CountryCard
                            countryQuantiny={country.countryQuantiny!}
                            countryCode={country.countryCode!}
                            countryName={t(`countyName.${country.countryCode}`)}
                            langNav={langNav} />
                    </div>
                ))
        }
    </>)
}