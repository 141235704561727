import { Link } from 'react-router-dom';
import { getFlag } from '../../service/ResourceService';
import { useGlobalContext } from '../../GlobalContext';
import { useTranslation } from 'react-i18next';

const Card = (props: {
    number: string;
    numberCode: string;
    countryCode: string;
    countryName: string;
    langNav: string;
}) => {
    const { t } = useTranslation();
    const { globalVariable, setGlobalVariable } = useGlobalContext();

    return (
        <div className="max-w-sm m-auto rounded overflow-hidden h-[14rem] sm:h-[22rem] md:h-[20rem] shadow-lg p-2 border border-gray-300">
            <div style={{ backgroundImage: "url(" + getFlag(props.countryCode) + ")", backgroundSize: "contain", backgroundPositionX: "center", backgroundRepeat: "no-repeat" }}
                className="w-[70%] sm:w-[62%] min-w-[8rem] relative h-[40%] sm:h-[45%] md:h-[40%] m-auto mt-2 sm:mt-8 " >
            </div>
            <div className="sm:px-6 py-2 sm:py-4 text-center ">
                <div className="font-bold sm:text-xl sm:mb-2 text-center"><p className='text-center'> {'+' + props.numberCode + props.number} </p></div>
                <p className="text-gray-900 font-semibold text-center text-sm sm:text-base">
                    {props.countryName}
                </p>
                <Link to={`${props.langNav}/sms/${props.numberCode + props.number}`}>
                    <button type="button" onClick={() => {
                        setGlobalVariable({
                            ...globalVariable,
                            number: props.numberCode + props.number,
                            countryCode: props.countryCode
                        })
                    }}
                        className="text-gray-900 bg-white hover:bg-gray-200 focus:ring-4 text-center inline-flex 
                    focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm w-[80%] py-2.5 m-2
                    items-center dark:focus:ring-gray-500 border border-gray-300 justify-center">
                        <span> {t("app.open")}</span>
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default Card;